<script>
import Form from '../../../../../../components/form';
import request from '../../../../../../utils/request';

export default {
  extends: Form,
  created() {
  },
  name: 'BtnConfigForm',
  data() {
    const self = this;
    return {
      rule: [
        {
          type: 'input',
          field: 'buttonName',
          title: '按钮名称',
          value: this.formConfig && this.formConfig.buttonName,
          // refresh: true,
          props: {
            placeholder: '请输入按钮名称',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入菜单名称',
            },
            {
              min: 2,
              max: 20,
              message: '长度在 2 到 20 个字符',
              trigger: 'blur',
            },
          ],
        },
        {
          type: 'input',
          field: 'buttonCode',
          title: '按钮编码',
          value: this.formConfig && this.formConfig.buttonCode,
          // refresh: true,
          props: {
            placeholder: '请输入按钮编码',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入菜单名称',
            },
            {
              min: 2,
              max: 20,
              message: '长度在 2 到 20 个字符',
              trigger: 'blur',
            },
          ],
        },
        {
          type: 'select',
          field: 'buttonType',
          title: '按钮类型',
          value: this.formConfig && this.formConfig.buttonType,
          // refresh: true,
          props: {
            placeholder: '请选择按钮位置',
            disabled: false,
            loading: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择按钮位置',
            },
          ],
          options: [
            {
              value: '0',
              label: '头部按钮',
            },
            {
              value: '1',
              label: '行内操作按钮',
            },
          ],
        },
        {
          type: 'select',
          field: 'buttonIcon',
          title: '按钮图标',
          value: this.formConfig && this.formConfig.buttonIcon,
          // refresh: true,
          props: {
            placeholder: '请选择按钮图标',
            disabled: false,
            loading: false,
            clearable: true, // 是否显示清空按钮
          },
          options: [
            {
              value: 'el-icon-plus',
              label: '添加',
            },
            {
              value: 'el-icon-delete',
              label: '删除',
            },
            {
              value: 'el-icon-edit',
              label: '编辑',
            },
            {
              value: 'el-icon-video-play',
              label: '启用',
            },
            {
              value: 'el-icon-video-pause',
              label: '禁用',
            },

          ],
        },
        {
          type: 'select',
          field: 'buttonOperationType',
          title: '操作类型',
          value: this.formConfig && this.formConfig.buttonOperationType,
          // refresh: true,
          props: {
            placeholder: '请选择操作类型',
            disabled: false,
            loading: false,
            clearable: true, // 是否显示清空按钮

          },
          on: {
            change(value) {
              console.log(value, '____________');
              if (Number(value) === 3) {
                console.log(self);
                self.hiddenFields(false, ['doCode']);
              } else {
                self.hiddenFields(true, ['doCode']);
              }
              if (Number(value) === 2) {
                self.hiddenFields(false, ['ask']);
              } else {
                self.hiddenFields(true, ['ask']);
              }
            },
          },
          options: [
            {
              value: '1',
              label: '简单',
            },
            {
              value: '2',
              label: '询问',
            },
            {
              value: '3',
              label: '弹窗',
            },

          ],
        },
        {
          type: 'input',
          field: 'doCode',
          title: '功能编码',
          value: this.formConfig && this.formConfig.doCode,
          visible: this.formConfig.buttonOperationType !== 3,
          // refresh: true,
          props: {
            hidden: true,
            placeholder: '请输入功能编码',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'configCode',
          title: '导入编码',
          value: this.formConfig && this.formConfig.configCode,
          visible: this.formConfig.buttonCode !== 'import',
          // refresh: true,
          props: {
            hidden: false,
            placeholder: '请输入导入编码',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'apiUrl',
          title: '提交apiUrl',
          value: this.formConfig && this.formConfig.apiUrl,
          // refresh: true,
          props: {
            placeholder: '请输入apiUrl',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'queryUrl',
          title: '查询queryUrl',
          value: this.formConfig && this.formConfig.queryUrl,
          visible: this.formConfig.buttonIcon !== 'el-icon-edit',
          // refresh: true,
          props: {
            placeholder: '请输入queryUrl',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'input',
          field: 'ask',
          title: '询问语',
          value: this.formConfig && this.formConfig.ask,
          // refresh: true,
          visible: this.formConfig.buttonOperationType !== 2,
          props: {
            hidden: true,
            placeholder: '请输入询问语',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },
        {
          type: 'switch',
          field: 'visible',
          title: '是否显示',
          value: this.formData.visible,
          refresh: true,
          props: {
            hidden: true,
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          options: [
            {
              label: '是',
              value: true,
            },
            {
              label: '否',
              value: false,
            },
          ],
        },
        {
          type: 'inputNumber',
          field: 'buttonOrder',
          title: '排序',
          value: this.formConfig && this.formConfig.buttonOrder,
          refresh: true,
          props: {
            hidden: true,
            // placeholder: '请输入询问语',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
        },

      ],
    };
  },

  mounted() {

  },
  methods: {
    submit() {
      const formData = this.getFormData();
      this.$emit('onGetList');
      if (formData) {
        const selectMenuCode = this.$store.state.config.selectMenu.functionCode;
        let url = '';
        let params = {};
        if (this.formConfig && this.formConfig.code === 'btnConfig-add') {
          url = '/mdm/mdmbutton/save';
          params = { ...formData, functionCode: selectMenuCode };
        } else if (this.formConfig && this.formConfig.code === 'btnConfig-edit') {
          url = '/mdm/mdmbutton/update';
          params = {
            id: this.formConfig.id,
            functionCode: this.formConfig.functionCode,
            ...formData,
          };
        }

        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            // this.closeModal();
            // this.getList();
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },

};
</script>
