<script>
import Form from '../../../../../components/form';
import request from '../../../../../utils/request';

export default {
  extends: Form,
  data() {
    return {
      rule: [
        {
          type: 'input',
          field: 'functionName',
          title: '菜单名称',
          props: {
            placeholder: '请输入菜单名称',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入菜单名称',
            },
            {
              min: 2,
              max: 50,
              message: '长度在 2 到 50 个字符',
              trigger: 'blur',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'functionLevel',
          title: '菜单等级',
          props: {
            placeholder: '请选择菜单等级',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择菜单等级',
            },
          ],
          col: {
            md: { span: 12 },
          },
          on: {
            change: this.changeFn,
          },
          options: [
            {
              value: '0',
              label: '一级菜单',
            },
            {
              value: '1',
              label: '下级菜单',
            },
          ],
        },
        {
          type: 'select',
          field: 'functionModule',
          title: '所属模块',
          props: {
            placeholder: '请选择所属模块',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          dictCode: 'module_group',
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择菜单等级',
            },
          ],
          col: {
            md: { span: 12 },
          },
          // options: [
          //   {
          //     value: '1',
          //     label: 'mdm',
          //   },
          //   {
          //     value: '2',
          //     label: 'dms',
          //   },
          //   {
          //     value: '3',
          //     label: 'tpm',
          //   },
          //   {
          //     value: '4',
          //     label: 'sfa',
          //   },
          // ],
        },
        {
          type: 'FormSelectTree',
          field: 'parentCode',
          title: '上级菜单',
          props: {
            options: [],
            size: 'small',
            placeholder: '请选择上级菜单',
            disabled: false,
            loading: false,
            clearable: true, // 是否显示清空按钮
            props: {
              parent: 'parentCode', // 父级唯一标识
              value: 'functionCode', // 唯一标识
              label: 'functionName', // 标签显示
              children: 'children', // 子级
            },
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择上级菜单',
            },
          ],
          col: {
            md: { span: 12 },
          },
          restful: '/mdm/mdmfunction/tree',
          restfulParams: {},
        },
        {
          type: 'input',
          field: 'functionUrl',
          title: '菜单地址',
          props: {
            placeholder: '请输入菜单地址',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'iconCode',
          title: '菜单图标',
          props: {
            placeholder: '请选择菜单图标',
            disabled: false,
            loading: true,
            clearable: true, // 是否显示清空按钮
            filterable: true,
            remote: true,
            remoteParams: 'iconName',
          },
          restful: '/mdm/mdmicon/listCondition',
          restfulParams: {
            iconType: '0',
          },
          optionsKey: {
            label: 'iconName',
            value: 'iconCode',
          },
          validate: [],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'functionConfig',
          title: '菜单类型',
          props: {
            placeholder: '请选择菜单类型',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'change',
              required: true,
              message: '请选择菜单类型',
            },
          ],
          col: {
            md: { span: 12 },
          },
          dictCode: 'function_config',
          // options: [
          //   {
          //     value: '0',
          //     label: '系统菜单',
          //   },
          // ],
        },
        {
          type: 'input',
          field: 'functionOrder',
          title: '排序',
          props: {
            placeholder: '请输入排序',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          validate: [
            {
              trigger: 'blur',
              required: true,
              message: '请输入排序',
            },
          ],
          col: {
            md: { span: 12 },
          },
        },
        {
          type: 'select',
          field: 'pageConfig',
          title: '是否允许页面配置',
          props: {
            placeholder: '请选择',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
          options: [
            {
              value: 'Y',
              label: '是',
            },
            {
              value: 'N',
              label: '否',
            },
          ],
        },
        {
          type: 'select',
          field: 'menuConfig',
          title: '是否包含流程',
          props: {
            placeholder: '请选择',
            disabled: false,
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
          options: [
            {
              value: 'Y',
              label: '是',
            },
            {
              value: 'N',
              label: '否',
            },
          ],
        },
        {
          type: 'input',
          field: 'relationBillCode',
          title: '流程表单',
          props: {
            placeholder: '流程表单',
            clearable: true, // 是否显示清空按钮
          },
          col: {
            md: { span: 12 },
          },
        },
      ],
      row: null,
    };
  },
  async created() {
    // this.rule = await this.getFormRule('form1');
    // console.log(this.rule);
  },
  mounted() {
    this.hiddenFields(true, 'relationBillCode');
    if (this.formConfig.functionLevel === '1') {
      this.hiddenFields(false, ['parentCode']);
    } else {
      this.hiddenFields(true, ['parentCode']);
    }

    if (this.formConfig.actionType === 'edit') {
      const parentCode = this.getRule('parentCode');

      request
        .post('/mdm/mdmfunction/query', { id: this.formConfig.id })
        .then((res) => {
          if (res.success) {
            this.row = res.result;
            parentCode.restfulParams = {
              excludeFunctionCode: res.result.functionCode,
            };

            this.hiddenFields(res.result.menuConfig !== 'Y', 'relationBillCode');
            this.setValue(res.result);
          }
        });
    }
    const menuConfig = this.getRule('menuConfig');
    menuConfig.on.change = (e) => {
      if (e === 'Y') {
        this.hiddenFields(false, 'relationBillCode');
      } else {
        this.hiddenFields(true, 'relationBillCode');
      }
    };
  },
  methods: {
    // 监听菜单层级选择
    changeFn(val) {
      this.hiddenFields(val === '0', ['parentCode']);
    },
    // 提交
    submit() {
      const formData = this.getFormData();

      if (formData) {
        let url = '/mdm/mdmfunction/save';
        let params = formData;

        if (this.formConfig.actionType === 'edit') {
          url = '/mdm/mdmfunction/update';
          params = Object.assign(this.row, formData);
          // params.id = this.formConfig.id;
        }
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });

            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
      console.log(formData);
    },
  },
};
</script>
