export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [
      {
        field: 'functionName',
        type: 'input',
        title: '名字',
        search: true,
      },
    ],
  },
  toolBarConfig: [
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-plus',
      code: 'add',
      name: '新增',
    },
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-plus',
      code: 'enable',
      name: '启用',
    },
    {
      type: 'button',
      // buttonType: 'primary',
      // icon: 'el-icon-plus',
      code: 'disable',
      name: '禁用',
    },
    {
      type: 'dropdown',
      buttonType: null,
      // icon: 'el-icon-delete',
      name: '删除',
      code: 'delete',
      props: {},
      children: [
        {
          name: '删除',
        },
      ],
    },
  ],
  columns: [
    {
      fixed: 'left', // 固定位置在左边
      type: 'radio', // 单选
      title: null,
      width: '50',
    },
    {
      field: 'functionName',
      title: '菜单名称',
      treeNode: true,
    },
    {
      field: 'functionCode',
      title: '菜单编码',
    },
    {
      field: 'functionUrl',
      title: '菜单地址',
    },
    {
      field: 'functionIcon',
      title: '菜单图标',
    },
    {
      field: 'functionOrder',
      title: '菜单排序',
    },
    {
      field: 'enableStatusName',
      title: '状态',
    },
    {
      title: '操作',
      width: '120',
      align: 'center',
      buttons: [
        {
          type: 'button',
          buttonType: 'primary',
          code: 'edit',
          name: '编辑',
          props: {
            type: 'primary',
          },
        },
        // {
        //   type: 'dropdown',
        //   buttonType: 'link',
        //   code: 'btnConfig',
        //   name: '按钮设置',
        //   props: {},
        // },
        // {
        //   type: 'dropdown',
        //   buttonType: 'link',
        //   code: 'authConfig',
        //   name: '权限对象',
        //   props: {},
        // },
        {
          type: 'dropdown',
          buttonType: 'link',
          code: 'delete',
          name: '删除',
          props: {},
        },

      ],
    },
  ],
  tableConfig: {
    border: true,
    treeConfig: {
      children: 'children',
    },
    idKey: 'id', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
  },
};
