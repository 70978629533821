<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import Modal from '../../../../../components/modal';
import BtnConfig from '../btnConfig';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    BtnConfig,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmfunction/allFunctionTree',
      configs,
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      formName: 'Form',
    };
  },
  created() {},
  methods: {
    modalClick({ val, row }) {
      this.formConfig = {};
      switch (val.code) {
        case 'add':
          this.formConfig = {
            actionType: val.code,
            title: '新增',
          };
          this.modalConfig.title = '新增';
          this.formName = 'Form';
          this.openModal();
          break;
        case 'edit':
          this.formConfig = {
            actionType: val.code,
            id: row.id,
            title: '编辑',
            functionLevel: row.functionLevel,
          };
          this.modalConfig.title = '编辑';
          this.formName = 'Form';
          this.openModal();
          break;
        case 'button':
          this.formConfig = {
            ...row,
          };
          break;
        case 'delete':
          this.deleteClick();
          break;
        case 'enable':
          this.enableOrDisable(row, 'enable');
          break;
        case 'disable':
          this.enableOrDisable(row, 'disable');
          break;
        case 'btnConfig':
          this.modalConfig = { ...this.modalConfig, title: '按钮设置' };
          console.log('store', this.$store);
          this.$store.commit('config/SET_SELECTEDMENU', row);
          this.formName = 'BtnConfig';
          this.openModal();
          break;
        default:
          break;
      }
    },
    deleteClick() {
      // const ids = row ? [row.id] : this.selection;

      const title = '此操作将永久删除此数据, 是否继续?';
      const url = '/mdm/mdmfunction/delete';
      const msg = '删除';
      const data = {
        params: { functionCode: this.selectRow[0].functionCode },
        url,
        title,
        msg,
      };
      // console.log(data);
      this.messageBox(data);
    },
    // 启用禁用
    enableOrDisable(row, type) {
      let url = '';
      if (type === 'enable') {
        url = '/mdm/mdmfunction/enable';
      } else {
        url = '/mdm/mdmfunction/disable';
      }
      request.post(url, {
        functionCode: row[0].functionCode,
      }).then((res) => {
        if (res.success) {
          this.$message.success('操作成功');
          this.getList();
        }
      });
    },
  },
};
</script>
