<script>
import TablePage from '../../../../../components/table_page';
import configs from './table/data';
import Modal from '../../../../../components/modal';
import BtnConfigForm from './form';
import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    BtnConfigForm,
  },
  data() {
    return {
      requestUrl: '/mdm/mdmbutton/list',
      params: this.$store.state.config.selectMenu,
      configs,
      formConfig: {},
      selection: [],
      modalConfig: {
        title: '新增按钮',
        visible: false,
        type: '',
      },
      formName: 'BtnConfigForm',
    };
  },
  created() {
    const url = '/mdm/mdmbutton/list';
    const { functionCode } = this.$store.state.config.selectMenu;
    const params = { functionCode };
    request.post(url, params).then((res) => {
      if (res.success) {
        if (Array.isArray(res.result.data)) this.tableData = res.result.data;
      }
    });
  },
  methods: {
    modalClick({ val, row }) {
      switch (val.code) {
        case 'btnConfig-add':
          this.formConfig = { code: val.code, ...row };
          this.modalConfig = '新增';
          this.openModal();
          break;
        case 'btnConfig-edit':
          console.log(row);
          this.modalConfig = '编辑';
          this.formConfig = { ...row };
          this.openModal();
          break;
        case 'btnConfig-delete':
          request.post('/mdm/mdmbutton/delete', [row.id], { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } })
            .then((res) => {
              if (res.success) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                });
                this.getList();
              }
            });
          break;
        default:
          break;
      }
    },
  },
};

</script>
